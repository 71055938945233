// @codingStandardsIgnoreFile
'use strict'

require('../../css/app.css')

document.addEventListener('DOMContentLoaded', function () {
  const currentPageType = document.body.getAttribute('data-pagetype')

  if (currentPageType) {
    switch (currentPageType) {
      case 'product-print':
        require('../pages/product-print')
        break
      case 'bookings':
        require('../pages/bookings')
        require('core/pages/listing')
        break
      default:
        break
    }
  }

  document.addEventListener('click', function (e) {
    if (e.target.matches('.row-link')) {
      e.stopPropagation()

      document.querySelector('.results-container').style.display = 'none'
      document.querySelector('.results-loading').style.display = 'block'

      window.document.location = this.querySelector('.row-target').getAttribute('href')
    }
  })
})
