// @codingStandardsIgnoreFile
'use strict'

const print = {
  init: function () {
    window.print()
  }
}

module.exports = print.init()
