// @codingStandardsIgnoreFile
'use strict'

const bookings = {
  init: function () {
    this.initPagination()
  },
  initPagination: function () {
    document.querySelectorAll('.filter-form').forEach(function (form) {
      const formName = form.getAttribute('name')

      document.querySelectorAll('.booking-container .pagination .page-prev').forEach(function (pagePrevEl) {
        pagePrevEl.addEventListener('click', function () {
          document.getElementById(formName + '_previousPage').value = 'previousPage'
          document.forms[formName].submit()
        })
      })

      document.querySelectorAll('.booking-container .pagination .page-next').forEach(function (pageNextEl) {
        pageNextEl.addEventListener('click', function () {
          document.getElementById(formName + '_nextPage').value = 'nextPage'
          document.forms[formName].submit()
        })
      })

      document.querySelectorAll('.booking-container .pagination .page-number .page').forEach(function (pageEl) {
        pageEl.addEventListener('click', function () {
          document.getElementById(formName + '_goToPage').value = pageEl.getAttribute('data-page')
          document.forms[formName].submit()
        })
      })
    })
  }
}

module.exports = bookings.init()
